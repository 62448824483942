.newsletter-container {
  margin: 100px 0 0 0;
  padding: 60px 12px 60px 12px;
  background: #1e5ef3;
}

.newsletter-container h3 {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 2.7rem;
  line-height: 3.5rem;
  color: #ffffff;
}

.newsletter-container p {
  margin-top: 25px;
  margin-right: 25%;
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #ffffff;
}

.input-wrapper {
    width: 60%;
    height: auto;
    background-color: #fff;
    border-radius: 7px;
    padding: 8px 8px 8px 25px;
}

.input-wrapper input {
  width: 80%;
  height: 40px;
  border: 0px solid transparent;
  border-radius: 10px;
}

.input-wrapper input:focus {
  outline: none;
}

.input-wrapper button {
  text-indent: -9999px;
  line-height: 0;
  width: 40px;
  height: 40px;
  float: right;
  background-color: #1e5ef3;
  border: 0px solid transparent;
  border-radius: 12px;
}

.input-wrapper button:after {
  content: url(../../Assets/arrow.svg);
  text-indent: 0;
  display: block;
  line-height: initial;
}

@media(max-width:543px){
  .newsletter-container h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@media(max-width:992px){
  .input-wrapper {
    width: 100%;
  }
}

@media(max-width:1200px){
  .newsletter-container p {
    margin-right: 0px;
  }
}


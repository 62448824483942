.highlights-section {
  width: 100%;
  padding: 50px ;
  background-color: #161616;
}

.highlights-title {
  font-family: HK Grotesk;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.highlights-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}


@media(max-width:576px){
  .highlights-title {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .highlights-subtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
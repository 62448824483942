.footer-container {
  background-color: #161616;
  color: #a3a3a3;
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 24px;
}

.footer-content .column1 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content .column2 {
  width: 25%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content .column2 a {
  margin-top: 20px;
}

.footer-content .column3 {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content .column3 ul {
  display: flex;
  margin-top: 25px;
}

.footer-content li img {
  height: 30px;
  width: auto;
}

@media (max-width: 576px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-content .column1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0px;
  }
  
  .footer-content .column2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: left;
    margin: 25px 0px;
  }
  
  .footer-content .column3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px 0px;
  }

}


.footer-copyright {
  font-size: 14px;
}

.footer-container a {
  text-decoration: none;
  color: #a3a3a3;
}

.footer-container li {
  margin-right: 20px;
}

.links li {
  margin: 18px 0 18px 0;
}

.footer-container a:hover {
  color: #ededed;
}

.footer-container .category-header {
  color: #ededed;
}

.footer-divider {
  border-top: 2px solid #a3a3a3;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 992px) {
  .footer-divider {
    width: 80%;
  }
}

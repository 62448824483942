.features-section {
  width: 100%;
  margin: 0;
  background-color: #f8f8fb;
}

.features-section:nth-child(2n) {
  background-color: #fff;
}

.features-section .title {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  color: #434343;
}

.features-section .subtitle {
  font-family: HK Grotesk;
  margin: 10px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #8f8f8f;
}

.features-section .feature-image {
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
  text-align: center;
}

@media(max-width:1200px){
  .features-section .feature-image {
    width: 70%;
  }
}

@media(max-width:1024px){
  .features-section .subtitle {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@media(max-width:992px){
  .features-section .feature-image {
    width: 80%;
  }
}

@media(max-width:768px){
  .features-section .feature-image {
    width: 90%;
  }
}

@media(max-width:576px){
  .features-section .feature-image {
    width: 100%;
  }
  .features-section .subtitle {
    font-size: 1rem;
    margin: 0 0 0 0;
  }
  .features-section .title {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

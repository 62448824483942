.header-container {
  padding: 10rem 12px 0px 12px;
}

.title {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.subtitle {
  color: #d8d8d8;
  padding: 25px 0 78px 0;
  font-family: HK Grotesk;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0em;
  text-align: left;
}

.header {
  position: relative;
  padding-bottom: 8rem;
  background-color: #161616;
}

.header .frame-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 36px;
}

@media (max-width: 576px) {
  .title {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 768px) {
  .header {
    padding-bottom: 9rem;
  }

  .header .frame-decoration {
    height: 52px;
  }
}

@media (min-width: 992px) {
  .header {
    padding-bottom: 10rem;
  }
  .header .frame-decoration {
    height: 68px;
  }
}

@media (min-width: 1200px) {
  .header {
    padding-bottom: 14.5rem;
  }

  .header .frame-decoration {
    height: 144px;
  }
}

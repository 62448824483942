.chair-section {
  width: 100%;
  padding: 50px;
  min-height: 120px;
  color: #434343;
}

.chair-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.chair-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}

.chair-subtitle1 {
    font-family: HK Grotesk;
    font-size: 1.2rem;
    margin: 20px 0 0 0;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #434343;
  }

.blog {
  background-color: #f8f8fb;
  margin-top: -100px;
  padding: 0 12px;
}

.blog .heading {
  font-family: HK Grotesk;
  font-size: 45px;
  font-weight: 700;
  color: #161616;
  text-align: center;
}

.blog span {
  background-color: #ebebeb;
  border-radius: 8px;
  color: #111;
  padding: 8px;
}

.blog-card-container {
  margin: 100px auto auto auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

.blog-card-container h2 {
  font-family: HK Grotesk;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
}

.blog-card {
  cursor: pointer;
  min-width: 313px;
  transition: 0.5s;
  /* padding: 35px;
  margin: auto;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.1));
  color: #8f8f8f; */
}

.blog-card:hover {
  box-shadow: 5px 10px 18px #dfdfdf;
}

.blog-card-title {
  color: #111;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 25px;
  text-overflow: ellipsis;
}

.blog-card-date {
  margin-left: auto;
  margin-right: auto;
  color: #808294;
  margin: auto;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.blog-card-desc {
  color: #808294;
  line-height: 27px;
  font-size: 16;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  /* line-clamp: 4;
  -webkit-box-orient: vertical; */
}

.blog-divider {
  border-top: 1px solid #ebebeb;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 85%;
}


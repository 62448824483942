.table-section2 {
  width: 100%;
  padding: 50px;
  background-color: #161616;
}

.highlights-bullet {
  font-family: HK Grotesk;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: 40px;
}

.highlights-bullet span {
  margin-right: 10px;
}

.table-section2 ul li {
  font-family: HK Grotesk;
  font-size: 1.3rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  /* text-align: center; */
  color: #fff;
  /* list-style-image: url('../../Assets/BulletArrow.svg'); */
  padding-left: 10px;
}

.notfound-page {
  background-color: rgba(30, 94, 243, 0.04);
  height: 100vh;
}

.notfound-section {
  width: 100%;
  padding: 50px;
  min-height: 120px;
  color: #434343;
}

.notfound-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.notfound-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}

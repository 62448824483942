@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css);
body {
  margin: 0;
  background-color: #fff;
  font-family: 'HK Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.header-container {
  padding: 10rem 12px 0px 12px;
}

.title {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.subtitle {
  color: #d8d8d8;
  padding: 25px 0 78px 0;
  font-family: HK Grotesk;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0em;
  text-align: left;
}

.header {
  position: relative;
  padding-bottom: 8rem;
  background-color: #161616;
}

.header .frame-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 36px;
}

@media (max-width: 576px) {
  .title {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 768px) {
  .header {
    padding-bottom: 9rem;
  }

  .header .frame-decoration {
    height: 52px;
  }
}

@media (min-width: 992px) {
  .header {
    padding-bottom: 10rem;
  }
  .header .frame-decoration {
    height: 68px;
  }
}

@media (min-width: 1200px) {
  .header {
    padding-bottom: 14.5rem;
  }

  .header .frame-decoration {
    height: 144px;
  }
}

.navigation-bar{
  background-color: rgba(22, 22, 22, 1);
}

.navigation-bar.scrolled {
  background-color: rgba(22, 22, 22, 0.65);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  transition: background-color 200ms linear;
}

sup {
  background-color: #1e5ef3;
  border-radius: 25px;
  color: white;
  padding: 2px 10px;
  margin-left: 2px;
}

.we-are-hiring {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: 7;
          animation-iteration-count: 7;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  3.25% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  9.25% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  15.75% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  21.75% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  25% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  3.25% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  9.25% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  15.75% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  21.75% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  25% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.feature {
  margin: 60px 0 100px 0;
}

.feature .heading {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 24px;
}


.feature-card-container {
  padding: 0 40px;
}

.feature .heading .heading-child {
  width: 400px;
  text-align: left;
}

@media(max-width:576px){
  .feature .heading .heading-child {
    width: 230px;
  }
  .feature-card-container {
    padding: 0 0px;
  }
}




.feature span {
  color: #1e5ef3;
  text-align: left;
}

@media(max-width:576px){
  .feature .heading {
    font-size: 1.8rem;
  }
}


/* #flip {
  height:45px;
  overflow:hidden;
  display:inline-block;
}

#flip > div > div {
  color:#1e5ef3;
  height:50px;
  line-height: 50px;
  margin-bottom:60px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
} */

@-webkit-keyframes show {
  0% {margin-top: 0px;}
  20% {margin-top: 45px;}
  40% {margin-top: 90px;}
  60% {margin-top: 135px;}
  80% {margin-top: 180px;}
  100% {margin-top:0px;}
}

@keyframes show {
  0% {margin-top: 0px;}
  20% {margin-top: 45px;}
  40% {margin-top: 90px;}
  60% {margin-top: 135px;}
  80% {margin-top: 180px;}
  100% {margin-top:0px;}
}

.features-card {
  /* position: relative; */
  /* width: 311px; */
  /* min-height: 350px; */
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(30, 94, 243, 0.15);
  border-radius: 20px;
  /* margin: auto; */
  padding: 25px;

}

@media (max-width: 768px) {
  .features-card {
    width: 100%;
  }
}

.features-card h6 {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #1e5ef3;
  height: 25px;
}

.card-container {
  margin: 50px auto 50px auto;
  padding: 0 12px;
}

.features-card h4 {
  font-family: HK Grotesk;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
}

.features-card p {
  margin-top: 50px;
  font-family: HK Grotesk;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5em;
  min-height: 3em;
  letter-spacing: 0em;
  text-align: left;
}

.features-card span {
  width: 113px;
  height: 0px;
  border-radius: 50px;
  border: 6px solid #1e5ef3;
  background-color: #1e5ef3;
}

.footer-container {
  background-color: #161616;
  color: #a3a3a3;
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 24px;
}

.footer-content .column1 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content .column2 {
  width: 25%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content .column2 a {
  margin-top: 20px;
}

.footer-content .column3 {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content .column3 ul {
  display: flex;
  margin-top: 25px;
}

.footer-content li img {
  height: 30px;
  width: auto;
}

@media (max-width: 576px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-content .column1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0px;
  }
  
  .footer-content .column2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: left;
    margin: 25px 0px;
  }
  
  .footer-content .column3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px 0px;
  }

}


.footer-copyright {
  font-size: 14px;
}

.footer-container a {
  text-decoration: none;
  color: #a3a3a3;
}

.footer-container li {
  margin-right: 20px;
}

.links li {
  margin: 18px 0 18px 0;
}

.footer-container a:hover {
  color: #ededed;
}

.footer-container .category-header {
  color: #ededed;
}

.footer-divider {
  border-top: 2px solid #a3a3a3;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 992px) {
  .footer-divider {
    width: 80%;
  }
}

.features-section {
  width: 100%;
  margin: 0;
  background-color: #f8f8fb;
}

.features-section:nth-child(2n) {
  background-color: #fff;
}

.features-section .title {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  color: #434343;
}

.features-section .subtitle {
  font-family: HK Grotesk;
  margin: 10px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #8f8f8f;
}

.features-section .feature-image {
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
  text-align: center;
}

@media(max-width:1200px){
  .features-section .feature-image {
    width: 70%;
  }
}

@media(max-width:1024px){
  .features-section .subtitle {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@media(max-width:992px){
  .features-section .feature-image {
    width: 80%;
  }
}

@media(max-width:768px){
  .features-section .feature-image {
    width: 90%;
  }
}

@media(max-width:576px){
  .features-section .feature-image {
    width: 100%;
  }
  .features-section .subtitle {
    font-size: 1rem;
    margin: 0 0 0 0;
  }
  .features-section .title {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

.highlights-section {
  width: 100%;
  padding: 50px ;
  background-color: #161616;
}

.highlights-title {
  font-family: HK Grotesk;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.highlights-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}


@media(max-width:576px){
  .highlights-title {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .highlights-subtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.steps {
  margin: 100px 0 100px 0;
}

.steps .heading {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 24px;
}

.steps span {
  color: #1e5ef3;
}

.steps-card-container {
  margin: 100px auto auto auto;
}

.steps-card {
  width: 100%;
  border-radius: 26px;
}

.steps-card h4 {
  font-family: HK Grotesk;
  color: #1e5ef3;
  font-weight: bold;
}

.steps-card p {
  font-family: HK Grotesk;
  color: #8f8f8f;
}

@media(max-width:576px){
  .steps .heading {
    font-size: 2rem;
  }
}

.newsletter-container {
  margin: 100px 0 0 0;
  padding: 60px 12px 60px 12px;
  background: #1e5ef3;
}

.newsletter-container h3 {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 2.7rem;
  line-height: 3.5rem;
  color: #ffffff;
}

.newsletter-container p {
  margin-top: 25px;
  margin-right: 25%;
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #ffffff;
}

.input-wrapper {
    width: 60%;
    height: auto;
    background-color: #fff;
    border-radius: 7px;
    padding: 8px 8px 8px 25px;
}

.input-wrapper input {
  width: 80%;
  height: 40px;
  border: 0px solid transparent;
  border-radius: 10px;
}

.input-wrapper input:focus {
  outline: none;
}

.input-wrapper button {
  text-indent: -9999px;
  line-height: 0;
  width: 40px;
  height: 40px;
  float: right;
  background-color: #1e5ef3;
  border: 0px solid transparent;
  border-radius: 12px;
}

.input-wrapper button:after {
  content: url(/static/media/arrow.2b4eacad.svg);
  text-indent: 0;
  display: block;
  line-height: normal;
  line-height: initial;
}

@media(max-width:543px){
  .newsletter-container h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@media(max-width:992px){
  .input-wrapper {
    width: 100%;
  }
}

@media(max-width:1200px){
  .newsletter-container p {
    margin-right: 0px;
  }
}


.blog {
  background-color: #f8f8fb;
  margin-top: -100px;
  padding: 0 12px;
}

.blog .heading {
  font-family: HK Grotesk;
  font-size: 45px;
  font-weight: 700;
  color: #161616;
  text-align: center;
}

.blog span {
  background-color: #ebebeb;
  border-radius: 8px;
  color: #111;
  padding: 8px;
}

.blog-card-container {
  margin: 100px auto auto auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

.blog-card-container h2 {
  font-family: HK Grotesk;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
}

.blog-card {
  cursor: pointer;
  min-width: 313px;
  transition: 0.5s;
  /* padding: 35px;
  margin: auto;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.1));
  color: #8f8f8f; */
}

.blog-card:hover {
  box-shadow: 5px 10px 18px #dfdfdf;
}

.blog-card-title {
  color: #111;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 25px;
  text-overflow: ellipsis;
}

.blog-card-date {
  margin-left: auto;
  margin-right: auto;
  color: #808294;
  margin: auto;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.blog-card-desc {
  color: #808294;
  line-height: 27px;
  font-size: 16;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  /* line-clamp: 4;
  -webkit-box-orient: vertical; */
}

.blog-divider {
  border-top: 1px solid #ebebeb;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 85%;
}


.table-header-container {
  padding: 15rem 12px 0px 12px;
}

.table-container {
  /* padding-left: 16rem; */
}

.table-title {
  font-family: HK Grotesk;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.5rem;
  /* letter-spacing: 0em; */
  text-align: left;
  color: #434343;
}

.table-subtitle {
  color: #434343;
  padding: 25px 0 78px 0;
  font-family: HK Grotesk;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
}

.table-header {
  position: relative;
  padding-bottom: 6rem;
}

.table-header .table-frame-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 36px;
}

@media (min-width: 768px) {
  .table-header {
    padding-bottom: 9rem;
  }

  .table-hero {
    margin-left: 20%;
  }

  .table-header .table-frame-decoration {
    height: 52px;
  }
}

@media (max-width: 900px) {
  .table-hero {
    margin-left: 10%;
  }
}

@media (max-width: 992px) {
  .table-hero {
    margin-left: -25%;
  }
}

@media (min-width: 992px) {
  .table-header {
    padding-bottom: 10rem;
  }

  .table-hero {
    margin-left: -5%;
  }

  .table-header .table-frame-decoration {
    height: 68px;
  }
}

@media (min-width: 1050px) {
  .table-hero {
    margin-left: -10%;
  }
}

@media (min-width: 1200px) {
  .table-header {
    padding-bottom: 6.5rem;
  }

  .table-hero {
    margin-left: -5%;
  }

  .table-header .table-frame-decoration {
    height: 144px;
  }
}

@media (min-width: 1650px) {
  .table-hero {
    margin-left: -24%;
  }
}

@media (max-width: 920px) {
  .table-hero {
    margin-left: -12%;
  }
}

@media (max-width: 820px) {
  .table-hero {
    margin-left: -5%;
  }
}

@media (max-width: 768px) {
  .table-hero {
    margin-left: -30%;
  }
}

@media (max-width: 720px) {
  .table-hero {
    margin-left: -20%;
  }
}

@media (max-width: 680px) {
  .table-hero {
    margin-left: -15%;
  }
}


@media (max-width: 590px) {
  .table-hero {
    margin-left: 0%;
  }
}

@media (max-width: 576px) {
  .table-title {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}

.table-section1 {
  width: 100%;
  padding: 50px;
  min-height: 280px;
  background-color: #f8f8fb;
  color: #434343;
}

.table-section1-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.table-section1-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}

.table-section2 {
  width: 100%;
  padding: 50px;
  background-color: #161616;
}

.highlights-bullet {
  font-family: HK Grotesk;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 800;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: 40px;
}

.highlights-bullet span {
  margin-right: 10px;
}

.table-section2 ul li {
  font-family: HK Grotesk;
  font-size: 1.3rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  /* text-align: center; */
  color: #fff;
  /* list-style-image: url('../../Assets/BulletArrow.svg'); */
  padding-left: 10px;
}

.table-section3 {
  width: 100%;
  padding: 50px;
  background-color: #f8f8fb;
  color: #434343;
}

.table-section3-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 40px 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.table-section3-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-style: italic;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}

.table-form {
  width: 100%;
  padding: 50px;
  background-color: #d0c6c6;
}

.form-modal div {
  border-radius: 18px !important;;
}

.form-title {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 26px;
  color: #161616;
  margin: 15px 0;
}

.form-label {
  color: #323232;
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.form-input {
  background: #ffffff;
  font-family: "HK Grotesk";
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4.66667px;
  padding: 12px;
  margin-bottom: 10px;
}

.chair-section {
  width: 100%;
  padding: 50px;
  min-height: 120px;
  color: #434343;
}

.chair-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.chair-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}

.chair-subtitle1 {
    font-family: HK Grotesk;
    font-size: 1.2rem;
    margin: 20px 0 0 0;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #434343;
  }

.notfound-page {
  background-color: rgba(30, 94, 243, 0.04);
  height: 100vh;
}

.notfound-section {
  width: 100%;
  padding: 50px;
  min-height: 120px;
  color: #434343;
}

.notfound-title {
  font-family: HK Grotesk;
  font-size: 2.5rem;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
  color: #434343;
}

.notfound-subtitle {
  font-family: HK Grotesk;
  font-size: 1.5rem;
  margin: 20px 0 0 0;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #434343;
}



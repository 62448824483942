.steps {
  margin: 100px 0 100px 0;
}

.steps .heading {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 24px;
}

.steps span {
  color: #1e5ef3;
}

.steps-card-container {
  margin: 100px auto auto auto;
}

.steps-card {
  width: 100%;
  border-radius: 26px;
}

.steps-card h4 {
  font-family: HK Grotesk;
  color: #1e5ef3;
  font-weight: bold;
}

.steps-card p {
  font-family: HK Grotesk;
  color: #8f8f8f;
}

@media(max-width:576px){
  .steps .heading {
    font-size: 2rem;
  }
}

.table-form {
  width: 100%;
  padding: 50px;
  background-color: #d0c6c6;
}

.form-modal div {
  border-radius: 18px !important;;
}

.form-title {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 26px;
  color: #161616;
  margin: 15px 0;
}

.form-label {
  color: #323232;
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.form-input {
  background: #ffffff;
  font-family: "HK Grotesk";
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4.66667px;
  padding: 12px;
  margin-bottom: 10px;
}

.feature {
  margin: 60px 0 100px 0;
}

.feature .heading {
  font-family: HK Grotesk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 24px;
}


.feature-card-container {
  padding: 0 40px;
}

.feature .heading .heading-child {
  width: 400px;
  text-align: left;
}

@media(max-width:576px){
  .feature .heading .heading-child {
    width: 230px;
  }
  .feature-card-container {
    padding: 0 0px;
  }
}




.feature span {
  color: #1e5ef3;
  text-align: left;
}

@media(max-width:576px){
  .feature .heading {
    font-size: 1.8rem;
  }
}


/* #flip {
  height:45px;
  overflow:hidden;
  display:inline-block;
}

#flip > div > div {
  color:#1e5ef3;
  height:50px;
  line-height: 50px;
  margin-bottom:60px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
} */

@keyframes show {
  0% {margin-top: 0px;}
  20% {margin-top: 45px;}
  40% {margin-top: 90px;}
  60% {margin-top: 135px;}
  80% {margin-top: 180px;}
  100% {margin-top:0px;}
}

.features-card {
  /* position: relative; */
  /* width: 311px; */
  /* min-height: 350px; */
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(30, 94, 243, 0.15);
  border-radius: 20px;
  /* margin: auto; */
  padding: 25px;

}

@media (max-width: 768px) {
  .features-card {
    width: 100%;
  }
}

.features-card h6 {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #1e5ef3;
  height: 25px;
}

.card-container {
  margin: 50px auto 50px auto;
  padding: 0 12px;
}

.features-card h4 {
  font-family: HK Grotesk;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
}

.features-card p {
  margin-top: 50px;
  font-family: HK Grotesk;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5em;
  min-height: 3em;
  letter-spacing: 0em;
  text-align: left;
}

.features-card span {
  width: 113px;
  height: 0px;
  border-radius: 50px;
  border: 6px solid #1e5ef3;
  background-color: #1e5ef3;
}

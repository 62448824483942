.table-header-container {
  padding: 15rem 12px 0px 12px;
}

.table-container {
  /* padding-left: 16rem; */
}

.table-title {
  font-family: HK Grotesk;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.5rem;
  /* letter-spacing: 0em; */
  text-align: left;
  color: #434343;
}

.table-subtitle {
  color: #434343;
  padding: 25px 0 78px 0;
  font-family: HK Grotesk;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
}

.table-header {
  position: relative;
  padding-bottom: 6rem;
}

.table-header .table-frame-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 36px;
}

@media (min-width: 768px) {
  .table-header {
    padding-bottom: 9rem;
  }

  .table-hero {
    margin-left: 20%;
  }

  .table-header .table-frame-decoration {
    height: 52px;
  }
}

@media (max-width: 900px) {
  .table-hero {
    margin-left: 10%;
  }
}

@media (max-width: 992px) {
  .table-hero {
    margin-left: -25%;
  }
}

@media (min-width: 992px) {
  .table-header {
    padding-bottom: 10rem;
  }

  .table-hero {
    margin-left: -5%;
  }

  .table-header .table-frame-decoration {
    height: 68px;
  }
}

@media (min-width: 1050px) {
  .table-hero {
    margin-left: -10%;
  }
}

@media (min-width: 1200px) {
  .table-header {
    padding-bottom: 6.5rem;
  }

  .table-hero {
    margin-left: -5%;
  }

  .table-header .table-frame-decoration {
    height: 144px;
  }
}

@media (min-width: 1650px) {
  .table-hero {
    margin-left: -24%;
  }
}

@media (max-width: 920px) {
  .table-hero {
    margin-left: -12%;
  }
}

@media (max-width: 820px) {
  .table-hero {
    margin-left: -5%;
  }
}

@media (max-width: 768px) {
  .table-hero {
    margin-left: -30%;
  }
}

@media (max-width: 720px) {
  .table-hero {
    margin-left: -20%;
  }
}

@media (max-width: 680px) {
  .table-hero {
    margin-left: -15%;
  }
}


@media (max-width: 590px) {
  .table-hero {
    margin-left: 0%;
  }
}

@media (max-width: 576px) {
  .table-title {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}
